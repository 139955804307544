.webform-component{
  &--rgpd{
    flex: 1 1 0;
    display: flex;
    min-width: 200px;
    &:not(#_){
      width: auto;
      margin-right: auto;
      margin-left: 0;
    }
    .form-checkboxes, .description{
      display: inline-block;
      vertical-align: top;
    }
    .description{
      max-width: calc(100% - 20px);
      font-size: 11px;

      a{
        color: #000;
        border-bottom: 2px solid #fff;
        &:hover{
          color: #000;
          border-bottom-color: #000;
        }
      }
    }
    #edit-submitted-rgpd{
      .form-type-checkbox{
        margin-top: 4px;
      }
      [type=checkbox]{
        position: absolute;
        z-index: -10;

        &:checked{
          + label{
            &:after{
              content: '';
              position: absolute; top: 4px; right: 4px; bottom: 4px; left: 4px;

              background-color: #e0e0e0;
            }
          }
        }
      }
      label{
        position: relative;
        z-index: 100;
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        font-size: 0.001px;
        cursor: pointer;

        &:before{
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          border: 2px solid #e0e0e0;
        }
      }
    }

    ~ .form-actions{
      flex: 0 1 auto;
      padding-left: 15px;
      &:not(#_){
        width: auto;
      }

      .webform-submit{
        margin-top: 20px
      }
    }

  }
  &--mention{
    white-space: nowrap;
    padding-right: 20px;

    span{
      color: $text-blue !important;
      font-weight: bold;
    }
    &:not(#_){
      width: auto;
      flex: 0 1 auto;
    }
  }
}

.main-contact .form-required{
  color: $text-blue;
}

/* user register form */
#user-register-form{
  #edit-field-user-rgpd{

    width: auto;

    .form-type-checkboxes{
      & > label{
        text-transform: inherit;
        margin: 0 0 25px 0;
        color: $text-blue;
      }
    }

    .form-required{
      display: none;
    }

    .form-checkboxes, .description{
      display: inline-block;
      vertical-align: top;
    }

    .description{
      max-width: calc(100% - 50px);
      font-size: 11px;

      a{
        color: #000;
        border-bottom: 2px solid #fff;
        &:hover{
          color: #000;
          border-bottom-color: #000;
        }
      }
    }

    .form-required{
      display: none;
    }

    #edit-field-user-rgpd-und{
      .form-type-checkbox{
        margin-top: 4px;
      }
      [type=checkbox]{
        position: absolute;
        z-index: -10;

        &:checked{
          + label{
            &:after{
              content: '';
              position: absolute; top: 4px; right: 4px; bottom: 4px; left: 4px;

              background-color: #e0e0e0;
            }
          }
        }
      }
      label{
        position: relative;
        z-index: 100;
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        font-size: 0.001px;
        cursor: pointer;

        &:before{
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          border: 2px solid #e0e0e0;
        }
      }
    }
  }
}

.dropdown-site {
  &.open {
    &:not(._) {
      z-index: 105;
    }
  }
}

#user-register-form #edit-field-user-rgpd .form-type-checkboxes > label {
  font-size: 0.01px; }

#user-register-form #edit-field-user-rgpd .form-type-checkboxes > label::before {
  content: '* Champs obligatoires';
  text-transform: inherit;
  margin: 0 0 25px 0;
  color: #e8467b;
  text-align: left;
  font-size: initial; }

.i18n-en #user-register-form #edit-field-user-rgpd .form-type-checkboxes > label::before {
  content: '* Required fields'; }

#user-login .description-link{
  font-size: 0.85em;
}

#user-login .description-link > a{
  text-decoration: underline;
}
