.messages{
  &.status{
    animation: flashbag 1s 4.5s ease-in-out forwards;
  }

  &.error{
    animation: flashbag 1s 9s ease-in-out forwards;
  }
}

@keyframes flashbag{
  from{
    position: static;
    opacity: 1;
    visibility: visible;
  }
  to{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: -10;
  }
}
