@import 'variables';
@import 'flashbag';
@import 'form';

/* CSS Document */

* {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
}

tbody {
  border-top: 0
}

a {
  text-decoration: none;
  color: #000;
}

ul, li, ul li.leaf {
  list-style: none;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

body {
  font: 400 16px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.wrap, .salade-moment, .liste-produit .field-content, #histoire .flex, #ateliers .flex, #valeurs .flex {
  margin: 0 auto;
  width: 1000px;
}

.actualites .wrap, .prefooter .wrap {
  margin: 0 auto;
  width: 850px;
}

#menu-header a:hover {
  text-decoration: underline;
}

.wrap::after, .listing-recherche .container-inline::after {
  display: block;
  clear: both;
  content: " ";
}

a, a:hover, nav a:hover::after, nav a::after, #user-login input[type=submit]:hover, #user-login input[type=submit], .menu-ancres a:hover::after, .menu-ancres a::after, input[type=submit], input[type=submit]:hover, .nav-is-stuck header, header, .actualites-accueil a:hover::after, .actualites-accueil a::after {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.flex, nav ul, .lang_dropdown_form form, .liste-produit .field-content, .menu-secondaire ul, #implantations ul, .prefooter .flex .bloc:nth-child(3) ul, .main-contact form {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

/* effets des A HREF */
.actualites .bloc p.readmore a, .engagements-accueil .bloc-engagements a {
  display: inline-block;
  float: right;
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  color: #c59a20;
  margin-top: 15px;
  background: #fff;
  padding: 6px 25px;
  border: 2px solid #c59a20;
  overflow: hidden;
  position: relative;
  z-index: 1;
  font-weight: 700;
}

.engagements-accueil .bloc-engagements a {
  color: #000;
  border: 2px solid #000;
  text-align: left;
  float: left;
}

.actualites .bloc p.readmore a:hover, .engagements-accueil .bloc-engagements a:hover {
  color: #fff;
}

.actualites .bloc p.readmore a::before, .engagements-accueil .bloc-engagements a::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 50%;
  right: 100%;
  margin: -15px 0 0 1px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c59a20;
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scale3d(1, 2, 1);
  transform: scale3d(1, 2, 1);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
}

.engagements-accueil .bloc-engagements a::before {
  background: #000;
}

.actualites .bloc p.readmore a:hover::before, .engagements-accueil .bloc-engagements a:hover::before {
  -webkit-transform: scale3d(11, 11, 1);
  transform: scale3d(11, 11, 1);
}

/* animation des blocs */
.salade-moment.anim .bloc,
.les-plus-gamme.anim > *,
.offre-espace-prive.anim > *,
.produits-phares.anim > *,
.liste-actu.anim,
.bloc.anim,
.vous-demarquer.anim,
#infos-marche.anim,
#temoignage.anim,
.actualites-accueil.anim,
.engagements-accueil.anim .bloc, .engagements-accueil.anim .bloc-engagements,
.maison-confiance .wrap.anim,
.liste-produit.anim,
.entreprise .anim {
  opacity: 0;
  -moz-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
  -moz-transform: translate3d(0px, 100px, 0px);
  -webkit-transform: translate3d(0px, 100px, 0px);
  -o-transform: translate(0px, 100px);
  -ms-transform: translate(0px, 100px);
  transform: translate3d(0px, 100, 0px);
}

.salade-moment.anim.active .bloc,
.les-plus-gamme.anim.active > *,
.offre-espace-prive.anim.active > *,
.produits-phares.anim.active > *,
.liste-actu.anim.active,
.bloc.anim.active,
.vous-demarquer.anim.active,
#infos-marche.anim.active,
#temoignage.anim.active,
.actualites-accueil.anim.active,
.engagements-accueil.anim.active .bloc, .engagements-accueil.anim.active .bloc-engagements,
.maison-confiance .wrap.anim.active,
.liste-produit.anim.active,
.entreprise .anim.active {
  opacity: 1;
  -ms-transition: all 900ms ease;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.iccookies_container {
  right: 0;
  left: 0;
}

@media all {

  .messages.status, .messages.error {
    text-align: center;
    background-color: rgba(197, 154, 32, 0.9);
    font-size: 17px;
    padding: 25px;
    background-image: none;
    color: #fff;
    border: 1px solid #997c29;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    right: 0;
    top: 10%;
    width: 50%;
    z-index: 999;
  }
  .messages.status .krumo-root, .messages.error .krumo-root {
    text-align: left;
  }

  /*************************************** HEADER ****************************************************/
  header {
    background: rgba(255, 255, 255, 0.75);
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 5px 3%;
  }
  header .flex {
    align-items: center
  }
  .nav-is-stuck header, header.cd-auto-hide-header.is-hidden {
    background: rgba(255, 255, 255, 0.9)
  }
  #logo {
    padding: 0 10px;
  }

  /*** MENU ***/
  nav {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    flex: 1 1 0%
  }
  nav li.leaf {
    list-style: none;
    padding: 0 20px;
    margin: 0;
  }
  nav a, .menu-secondaire li a, .menu-ancres a {
    color: #000;
    position: relative;
  }
  nav a::after, .menu-ancres a::after, .actualites-accueil a::after {
    content: "";
    width: 0;
    background: #000;
    position: absolute;
    bottom: -7px;
    left: 0;
    height: 2px;
  }
  nav a:hover::after, .menu-ancres a:hover::after, .actualites-accueil a:hover::after, nav li.active-trail a::after {
    width: 20px;
  }
  nav li:last-child {
    padding: 0;
  }
  nav li:last-child a {
    background: #fff;
    border-radius: 3px;
    padding: 6px 20px;
  }
  nav li:last-child a::after {
    content: none;
  }
  nav li:last-child a:hover {
    background: #c59a20;
    color: #fff;
  }
  nav li:nth-last-child(2) {
    margin-left: auto;
  }

  .lang_dropdown_form form {
    align-items: center;
    justify-content: space-between;
    width: 65px;
  }
  .langue {
    margin-left: auto;
    padding: 0 22px
  }
  .langue select {
    border: 0;
    min-width: 45px;
    background: url(../images/pictos/arrow-langue.png) no-repeat right center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
  }
  .langue .form-item {
    margin: 0;
  }
  .langue img {
    vertical-align: middle
  }

  #recherche {
    width: 30px;
    position: relative;
    height: 32px;
    z-index: 999;
  }
  #recherche .search-form {
    margin-bottom: 0
  }
  .sb-search {
    position: absolute;
    margin-top: 0;
    width: 0%;
    min-width: 30px;
    height: 32px;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    transition: width 0.3s;
    -webkit-backface-visibility: hidden;
    right: 0;
  }
  .sb-search-input {
    position: absolute;
    top: 0;
    background: transparent;
    right: 0;
    border: none;
    outline: none;
    width: 100%;
    height: 32px;
    margin: 0;
    z-index: 10;
    border-radius: 3px;
    padding: 0 20px 0 45px;
    font-size: 14px;
    font-weight: 700;
    color: #000;
  }
  .sb-search-input::-webkit-input-placeholder {
    color: #000;
  }
  .sb-search-input:-moz-placeholder {
    color: #000;
  }
  .sb-search-input::-moz-placeholder {
    color: #000;
  }
  .sb-search-input:-ms-input-placeholder {
    color: #000 !important;
  }
  .sb-icon-search, .sb-search-submit {
    width: 30px;
    height: 18px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 0;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .sb-search-submit { /*background: #fff;  IE needs this */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
    filter: alpha(opacity=0); /* IE 5-7 */
    opacity: 0;
    color: transparent;
    border: none;
    outline: none;
    z-index: -1;
  }
  .sb-icon-search {
    color: #fff;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    background: url(../images/pictos/search.png) no-repeat center;
    z-index: 90;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
  }
  /* Open state */
  .sb-search.sb-search-open, .no-js .sb-search {
    width: 385px;
  }
  .sb-search.sb-search-open .sb-search-submit, .no-js .sb-search .sb-search-submit {
    z-20: 90;
  }
  .sb-search.sb-search-open .sb-search-input {
    background: #fff;
  }

  /*************************************** ACCUEIL ****************************************************/
  .diaporama {
    position: relative
  }
  .diaporama img {
    width: 100%
  }
  .texte-diaporama {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    text-shadow: 3px 3px 10px #000;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 750px;
    width: 750px;
  }
  .texte-diaporama h1 {
    text-transform: uppercase;
    font-size: 37px;
    line-height: 42px;
    margin-bottom: 5px;
  }
  .texte-diaporama p {
    font-size: 33px;
    font-style: italic;
  }

  /* bloc actu */
  .actualites-accueil {
    max-width: 340px;
    background: #c59a20;
    padding: 25px 30px;
    margin-top: -80px;
    z-index: 9;
    position: relative;
    margin-left: auto;
    font-size: 14px;
    line-height: 20px;
  }
  .actualites-accueil a {
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 10px;
    position: relative;
  }
  .actualites-accueil a::after {
    bottom: -4px;
  }

  /* nos engagements */
  .front .diaporama + .wrap > *:only-child {
    margin-top: 100px;
  }
  .engagements-accueil {
    justify-content: center;
    padding: 10px 0 150px
  }
  .engagements-accueil .bloc.lien {
    width: 245px;
    margin: 0 0 10px 10px;
  }
  .engagements-accueil .bloc-engagements {
    width: 49%;
    margin: 0 0 10px 0;
    padding: 0 50px 0 0
  }
  .engagements-accueil h2 {
    font: 700 45px/38px 'Dancing Script', Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
  }

  .engagements-accueil figure {
    position: relative
  }
  .engagements-accueil figcaption {
    position: absolute;
    bottom: 20px;
    width: 90%;
    font-weight: 700;
    color: #fff;
    background: #c59a20;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 15px
  }
  .engagements-accueil figcaption p {
    line-height: 16px;
  }

  /* maison de confiance */
  .maison-confiance .wrap {
    width: 390px;
    padding: 160px 0;
    text-align: center
  }
  .maison-confiance h2 {
    font: 700 45px 'Dancing Script', Arial, Helvetica, sans-serif;
    color: #fff;
    text-shadow: 3px 3px 20px #000;
  }
  .maison-confiance h3 {
    text-shadow: 3px 3px 20px #000;
  }
  .maison-confiance li {
    display: inline
  }


  /*************************************** CONTENU ****************************************************/
  main {
    margin-bottom: 0;
  }

  /***** fil d'ariane ****/
  #breadcrumbs {
    padding: 20px 0;
    font-size: 11px;
  }
  #breadcrumbs li {
    float: left;
    padding: 0 2px;
  }
  #breadcrumbs li a {
    color: #003c65;
  }


  /******************* CONTENU **************************/

  article p, #infos-marche p, .engagements-accueil p, #infos-marche .bloc:first-child li {
    line-height: 25px;
  }
  article li {
    line-height: 20px;
  }


  /******************* GAMME **************************/
  .gamme .en-tete {
    max-height: 420px;
    overflow: hidden;
    position: relative;
  }
  .gamme .en-tete img {
    width: 100%;
  }
  .gamme .en-tete h1, .page-recherche .en-tete h1, .actualites .en-tete h1, .contact .en-tete h1, .engagements .en-tete h1, .listing-produits .en-tete h1, .entreprise .en-tete h1, .article-actualite .en-tete h1 {
    position: absolute;
    z-index: 9;
    top: 50%; /* transform: translateY(-50%);*/
    color: #fff;
    font-size: 35px;
    text-shadow: 3px 3px 10px #000;
    text-transform: uppercase;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 40px;
  }

  .salade-moment {
    padding: 50px 0;
    justify-content: space-between;
    align-items: center
  }
  .salade-moment > * {
    width: 48%
  }
  .salade-moment h2 {
    font: 700 45px/38px 'Dancing Script', Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
  }
  .salade-moment h3 {
    font-size: 22px;
    color: #c59a20;
    text-transform: uppercase;
    margin-bottom: 7px;
  }
  .salade-moment p {
    line-height: 25px;
  }

  .les-plus-gamme {
    background: #f5e0c5;
    padding: 60px 0 90px;
  }
  .les-plus-gamme h2, .produits-phares h2, .liste-produit h2 {
    font: 700 45px 'Dancing Script', Arial, Helvetica, sans-serif;
    text-align: center;
    margin-bottom: 30px;
  }
  .les-plus-gamme .flex {
    justify-content: space-between;
    text-align: center
  }
  .les-plus-gamme .bloc {
    width: 23%;
  }
  .les-plus-gamme h3, .maison-confiance h3, .engagements-accueil h3, .article-actualite .wrap h3, .listing-recherche li h3 {
    font-size: 22px;
    line-height: 25px;
    color: #c59a20;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  .les-plus-gamme p {
    line-height: 22px;
  }
  .les-plus-gamme .img-gamme {
    min-height: 145px;
    position: relative;
  }
  .les-plus-gamme .img-gamme img {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .produits-phares {
    padding: 55px 0 100px;
  }

  .produits-phares li, .liste-produit li {
    position: relative;
  }

  .produits-phares .slide-produits {
    position: relative;
  }

  .produits-phares li .titre-produit-phare, .liste-produit li .titre-produit-phare {
    position: absolute;
    height: 100%;
    bottom: 0;
    z-index: 9;
    width: 100%;
    ground: -moz-linear-gradient(270deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.5)));
    background: -webkit-linear-gradient(270deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
    background: -o-linear-gradient(270deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
    background: -ms-linear-gradient(270deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0); /* ie6-9 */
  }

  .produits-phares li .titre-produit-phare p, .liste-produit li .titre-produit-phare p, .produits-phares li .titre-produit-phare h3, .liste-produit li .titre-produit-phare h3 {
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    bottom: 30px;
    position: absolute;
    padding: 0 15px;
  }

  .produits-phares .flex-direction-nav {
    display: none;
  }

  .produits-phares {
    padding: 55px 0 100px;
  }
  .produits-phares li, .liste-produit li {
    position: relative;
  }
  .produits-phares .slide-produits {
    position: relative;
  }
  .produits-phares li .titre-produit-phare, .liste-produit li .titre-produit-phare {
    position: absolute;
    height: 100%;
    bottom: 0;
    z-index: 9;
    width: 100%;
    ground: -moz-linear-gradient(270deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.5)));
    background: -webkit-linear-gradient(270deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
    background: -o-linear-gradient(270deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
    background: -ms-linear-gradient(270deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0); /* ie6-9 */
  }
  .produits-phares li .titre-produit-phare p, .liste-produit li .titre-produit-phare p {
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    bottom: 30px;
    position: absolute;
    padding: 0 15px;
  }
  .produits-phares .flex-direction-nav {
    display: none;
  }

  .offre-espace-prive {
    padding: 75px 2% 60px;
    background: #c59a20;
    text-align: center;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .offre-espace-prive p a, .maison-confiance a, .liste-produit p a, .page-user .creation-compte a, #implantations p:last-child a {
    font-size: 14px;
    color: #fff;
    background: #000;
    display: inline-block;
    padding: 10px 58px;
    border-radius: 3px;
    margin-top: 20px;
    border: 2px solid #000
  }
  .maison-confiance a, .liste-produit p a, .page-user .creation-compte a, #implantations p:last-child a {
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 30px;
  }
  .offre-espace-prive p a:hover, input[type=submit]:hover {
    background: #c59a20;
    color: #000
  }
  .maison-confiance a:hover, .liste-produit p a:hover, .page-user .creation-compte a:hover, #implantations p:last-child a:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #000
  }


  /******************* NOS ENGAGEMENTS **************************/
  .cd-secondary-nav {
    position: absolute;
    z-index: 9;
    bottom: 0; /* left: 0; */
    right: 0;
    padding: 17px 0;
    text-align: center;
    background: rgba(197, 154, 32, 0.8)
  }
  .cd-secondary-nav.fixed {
    bottom: inherit;
  }
  .engagements .en-tete .menu-ancres li, .gamme .en-tete .menu-ancres li, .listing-produits .en-tete .menu-ancres li, .entreprise .en-tete .menu-ancres li {
    display: inline;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding: 0 22px;
  }

  /* services */
  #services, .listing-recherche .wrap {
    padding: 60px 0
  }
  #services h2, .vous-demarquer h2, #temoignage .titre, #infos-marche h2, .listing-recherche h2 {
    font: 700 45px/42px 'Dancing Script', Arial, Helvetica, sans-serif;
    text-align: center;
    margin-bottom: 10px;
  }
  #services h2 + p {
    text-align: center;
    margin-bottom: 30px;
  }
  #services .flex {
    justify-content: space-between
  }
  #services .flex > * {
    width: 49%;
    margin-bottom: 25px;
  }
  #services .flex img {
    margin-bottom: 15px
  }
  #services h3, .vous-demarquer h3, #infos-marche h3, .entreprise h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: #c59a20;
    text-transform: uppercase;
    margin-bottom: 7px;
  }
  #services p {
    line-height: 22px
  }

  /* vous démarquer */
  .vous-demarquer {
    justify-content: space-between;
    align-items: center;
    background: #f5e0c5;
  }
  .vous-demarquer > *, #infos-marche > * {
    width: 50%;
  }
  .vous-demarquer .bloc:first-child img, #infos-marche .bloc:nth-child(2) img {
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    height: auto;
  }
  .vous-demarquer .bloc:nth-child(2) {
    padding: 30px 5%;
    text-align: center
  }
  .vous-demarquer .bloc:nth-child(2) .flex {
    justify-content: space-between
  }
  .vous-demarquer .bloc:nth-child(2) .flex > * {
    width: 48%;
    margin-top: 20px;
    font-size: 14px;
  }
  article .vous-demarquer p {
    line-height: 18px;
  }
  .vous-demarquer .bloc:nth-child(2) .flex img {
    margin-bottom: 7px
  }
  .vous-demarquer .bloc:nth-child(2) .flex strong {
    font-size: 18px
  }

  /* témoignage */
  #temoignage {
    position: relative;
    padding: 80px 0 135px;
    text-align: center;
  }
  #temoignage .titre {
    margin-bottom: 25px;
  }
  .slide-temoignage {
    max-width: 620px;
    margin: 0 auto;
    position: relative;
  }
  article .slide-temoignage li {
    font-size: 22px;
    line-height: 30px;
    position: relative;
  }
  article .slide-temoignage li strong {
    font-size: 25px;
  }

  /* infos marché */
  #infos-marche {
    justify-content: space-between;
    align-items: center;
    background: #ededed;
  }
  #infos-marche .bloc:first-child {
    padding: 0 75px;
    max-width: 550px;
    margin-left: auto;
  }
  #infos-marche h2 {
    text-align: left;
  }


  /******************* LISTE DES PRODUITS **************************/
  .liste-produit {
    padding: 75px 0
  }
  .liste-produit.views-row-even {
    background: #ededed
  }
  .liste-produit .field-content {
    justify-content: space-between;
  }
  .liste-produit .field-content a {
    display: block;
    width: 32.5%;
  }
  .liste-produit p {
    text-align: center;
    margin-top: 20px;
  }


  /********************** ENTREPRISE ******************************/
  #histoire {
    padding: 75px 0;
  }
  #histoire .flex {
    justify-content: space-between
  }
  #histoire .flex .bloc:first-child {
    width: 66%;
  }
  #histoire .flex .bloc:nth-child(2) {
    width: 30%;
  }

  #ateliers {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: #f5e0c5;
    padding: 140px 0 190px;
  }
  #ateliers .bloc {
    max-width: 455px;
  }
  #ateliers ul {
    margin-top: 40px;
    font-weight: 700
  }
  #ateliers li {
    display: inline-block;
    float: left;
    max-width: 160px;
  }
  #ateliers li img {
    float: left;
    margin-right: 8px
  }

  #chiffres {
    padding: 50px 0 90px;
  }
  #chiffres h2, #valeurs h2, #signatures h2 {
    text-align: center;
  }
  #chiffres .flex {
    justify-content: center;
    margin-top: 25px;
  }
  #chiffres .flex .bloc {
    margin: 0 40px;
    text-align: center
  }
  #chiffres .flex .bloc p {
    font-size: 20px;
    color: #c59a20;
    text-transform: uppercase;
    font-weight: 700
  }
  #chiffres .flex .bloc p strong {
    font-size: 60px;
  }
  #chiffres .flex .bloc img {
    margin-bottom: 5px;
  }
  #chiffres .flex .bloc:first-child img {
    margin-bottom: 25px;
  }
  #chiffres .flex .bloc p em {
    font-style: italic;
    color: #000;
    text-transform: none;
  }

  #implantations {
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #f5e0c5;
    padding: 210px 0;
    text-align: center
  }
  #implantations ul, #signatures .flex {
    margin-top: 45px;
    justify-content: center;
  }
  #implantations li {
    margin: 0 5px 10px;
  }

  #valeurs {
    padding: 75px 0
  }
  #valeurs .flex {
    justify-content: space-between;
    margin-top: 40px;
  }
  #valeurs .flex .bloc {
    width: 31%;
  }

  #signatures {
    background: #ededed;
    padding: 70px 0 40px
  }
  #signatures .bloc {
    max-width: 235px;
    margin: 0 35px 30px;
    text-align: center
  }
  #signatures .bloc img {
    margin-bottom: 10px;
  }


  /***************** 	Page de recherche ******************************/
  .listing-recherche li + li {
    margin-top: 30px;
  }
  .listing-recherche li {
    background: #f1f1f1;
    padding: 40px;
  }
  .listing-recherche li h3 a {
    color: #c59a20;
  }
  .listing-recherche .search-results .search-snippet-info {
    padding: 0;
  }

  .listing-recherche input[type=text] {
    padding: 8px 10px;
    background-color: #fff;
    border-radius: 0;
    border: 2px solid #e0e0e0;
    font: 400 16px 'Open Sans', Arial, Helvetica, sans-serif;
  }
  article .listing-recherche input[type=submit] {
    float: inherit;
  }
  article .listing-recherche .search-form {
    margin-bottom: 25px;
  }
  article .listing-recherche h2 {
    margin: 40px 0;
  }

  /***************** Contact (formulaire) ********************************/
  .main-contact .wrap {
    background: #fff;
    padding: 45px 30px;
  }
  .main-contact h2, .entreprise h2 {
    font: 700 45px/38px 'Dancing Script', Arial, Helvetica, sans-serif;
    margin-bottom: 15px;
  }
  .main-contact p {
    line-height: 25px;
  }
  article input[type=submit] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 155px;
    text-transform: uppercase;
    float: right;
    color: #fff;
    height: 40px;
    margin: auto;
    background: #000;
    font: 700 14px 'Open Sans', Arial, Helvetica, sans-serif;
    border: 0;
    cursor: pointer;
  }
  .page-user-password article input[type=submit] {
    width: 100%;
  }
  .page-user-register article input[type=submit] {
    width: 250px;
    float: none;
  }
  .page-user-register article #edit-actions {
    text-align: center
  }
  .main-contact .wrap form {
    margin: 30px 0 0
  }
  .main-contact .wrap form::after {
    clear: both;
    content: "";
    display: block;
  }
  /*.main-contact .wrap form + p:not(:first-child) { font-size:12px; font-style:italic; line-height: 16px;}*/

  .main-contact form {
    justify-content: space-between
  }
  .main-contact form label {
    text-transform: uppercase;
  }
  .main-contact form label.error {
    text-transform: none;
    padding-top: 5px;
  }
  .main-contact form > * {
    width: 49%;
    margin: 0 0 20px 0;
  }
  .page-user-password .main-contact form > *, .page-user-reset .main-contact form > * {
    width: 100%;
  }
  .main-contact form > .width100, .main-contact form > .form-actions, .main-contact form > #edit-account, .main-contact form .form-type-password-confirm > * {
    width: 100%;
  }
  .main-contact .form-required {
    color: #c59a20
  }
  .main-contact input[type=text], .main-contact input[type=email], .main-contact input[type=password], .main-contact textarea, .main-contact select {
    padding: 10px;
    background-color: #fff;
    border-radius: 0;
    width: 100%;
    border: 2px solid #e0e0e0;
    font: 400 16px 'Open Sans', Arial, Helvetica, sans-serif;
  }
  .main-contact .form-item textarea.error, .main-contact input[type=text].error, .main-contact input[type=email].error, .main-contact input[type=password].error {
    border: 2px solid #921d2e;
  }
  .main-contact input[type=text], .main-contact input[type=email], .main-contact input[type=password], .main-contact select {
    height: 46px;
  }
  .main-contact select option {
    padding: 3px;
  }

  .main-contact form .form-type-password-confirm .password-strength, .main-contact form .form-type-password-confirm div.password-confirm {
    width: 18em;
    margin-top: 0
  }
  .main-contact form .form-type-password-confirm .form-item-pass-pass1 {
    margin: 30px 0 25px;
  }
  div.form-item div.password-suggestions {
    width: 100%;
    border: 0;
    background: #f3d377;
    padding: 15px;
  }


  /********************************* Blog ********************************/
  .listing-actualites, .main-contact, .main-actualite {
    background: #ededed;
  }
  .actualites .en-tete, .page-recherche .en-tete, .contact .en-tete, .engagements .en-tete, .listing-produits .en-tete, .entreprise .en-tete, .article-actualite .en-tete {
    min-height: 450px;
    position: relative;
  }
  .actualites .wrap, .main-contact {
    padding: 55px 0
  }
  .actualites .liste-actu {
    background: #fff;
    margin-bottom: 22px;
    padding: 30px;
    justify-content: space-between;
  }
  .actualites .liste-actu .bloc:first-child {
    width: 55%;
  }
  .actualites .liste-actu .bloc:last-child {
    width: 41%;
  }
  .actualites .liste-actu .date, .main-actualite .date {
    font-weight: 700;
    font-size: 18px;
    color: #c59a20;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .actualites h2, .actualites-accueil h2 {
    font-size: 23px;
    line-height: 25px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .actualites .bloc p {
    line-height: 25px;
  }

  .page-actualites .item-list {
    font-weight: 700;
    margin-top: 40px;
  }
  .page-actualites .item-list .pager-current {
    color: #c59a20;
  }

  /* page d'une actualité */
  .main-actualite {
    padding: 55px 0;
  }
  .article-actualite .wrap {
    background: #fff;
    padding: 45px 30px;
  }
  .article-actualite .wrap h2 {
    font: 700 35px/30px 'Dancing Script', Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
  }
  .article-actualite .wrap li {
    line-height: 24px;
  }
  .article-actualite .wrap li::before, #infos-marche .bloc:first-child li::before, .node-type-page-simple article li::before {
    content: "\2022\ ";
    color: #c59a20;
    padding-right: 5px;
    font-weight: 700;
  }


  /********************************* User ********************************/
  .profile, #user-login {
    margin: 150px auto 80px;
    width: 60%;
  }
  #user-login {
    max-width: 400px;
  }
  #user-login label {
    display: block;
    margin-bottom: 10px;
    font: 700 36px 'Dancing Script', Arial, Helvetica, sans-serif;
  }
  #user-login input[type="text"], #user-login input[type="email"], #user-login input[type="password"] {
    background: #fff0c7;
    border: 0 none;
    font: 400 16px 'Open Sans', Arial, Helvetica, sans-serif;
    height: 45px;
    padding: 0 15px;
    width: 100%;
  }
  #user-login input[type=submit] {
    display: block;
    width: 220px;
    text-transform: uppercase;
    color: #fff;
    height: 50px;
    margin: 0 auto;
    margin-top: 20px;
    background: #c59a20;
    font: 700 16px 'Open Sans', Arial, Helvetica, sans-serif;
    cursor: pointer;
    border: 0;
    -webkit-appearance: none;
  }
  #user-login input[type=submit]:hover {
    background: #000;
  }

  .page-user .creation-compte {
    background: #c59a20 none repeat scroll 0 0;
    font-weight: 700;
    line-height: 25px;
    padding: 75px 2% 60px;
    text-align: center;
  }
  .page-user .creation-compte h2 {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  /*************************************** PREFOOTER ****************************************************/
  .prefooter {
    background: #000;
    color: #fff;
    font-size: 14px;
    padding: 30px 0 75px;
  }
  .prefooter .flex {
    justify-content: space-between;
  }
  .prefooter .flex .bloc:first-child {
    font-size: 13px;
    width: 32%;
  }
  .prefooter .flex .bloc table tr td:first-child {
    width: 90px;
  }
  .prefooter .flex .bloc:nth-child(2) {
    width: 17%; /* margin-top: 15px*/
  }
  .prefooter .flex .bloc:nth-child(2) li + li {
    margin-top: 15px;
  }
  .prefooter .flex .bloc:nth-child(3) {
    width: 30%; /* margin-top: 15px*/
  }
  .prefooter .flex .bloc:nth-child(3) li {
    display: inline;
  }
  .prefooter .flex .bloc:nth-child(3) li:nth-child(3) {
    margin-top: 15px;
    display: block;
  }
  .prefooter .flex .bloc:nth-child(3) ul {
    justify-content: space-between;
  }
  .prefooter .flex .bloc:nth-child(3) li:nth-child(3) a {
    background: url(../images/SDP_Rungis_epicerie_fine.png) no-repeat left center;
    padding-left: 60px;
    display: block;
  }
  .prefooter a {
    text-transform: uppercase;
  }


  /*************************************** PIED DE PAGE ****************************************************/
  footer {
    background: #000;
    padding: 20px 0;
    color: #fff;
  }

  #menu-footer {
    text-align: center;
    font-size: 12px;
  }
  #menu-footer ul.menu {
    text-align: center;
  }
  #menu-footer li {
    display: inline;
    padding: 0 15px;
  }
  #menu-footer li + li {
    border-left: 1px solid #fff;
  }
  #menu-footer a, .prefooter a {
    color: #fff;
  }
  #menu-footer a:hover, .prefooter a:hover {
    color: #c59a20;
  }


}


@media all and (max-width: 1440px) {
  #ateliers {
    background-position: right -50px bottom;
  }
  .texte-diaporama {
    max-width: 550px;
  }
  .texte-diaporama h1 {
    font-size: 30px;
    line-height: 34px;
  }
  .texte-diaporama p {
    font-size: 28px;
  }
}

@media all and (max-width: 1300px) {
  .vous-demarquer .bloc:first-child, .vous-demarquer .bloc:nth-child(2) {
    padding: 30px 20px;
  }
}

@media all and (max-width: 1275px) {
  .langue {
    margin-left: inherit;
  }
  nav a::after {
    content: none;
  }
  nav ul.dl-menu li:last-child a {
    border-radius: 0;
    padding: 15px 20px;
    border: 3px solid #c59a20;
  }
  nav > ul.menu {
    font-size: 12px;
  }
}

@media all and (max-width: 1180px) {
  nav > ul.menu li.leaf {
    padding: 0 10px;
  }
}

@media all and (max-width: 1135px) {
  .vous-demarquer .bloc:first-child {
    width: 100%;
    padding: 0;
  }
  .vous-demarquer .bloc:nth-child(2) {
    width: 100%;
    padding: 60px;
  }
  .vous-demarquer h2 {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  #infos-marche {
    align-items: stretch;
  }
  #infos-marche .bloc:first-child {
    padding: 30px 20px;
    max-width: 50%;
    margin: 0;
  }
  .vous-demarquer .bloc:first-child img, #infos-marche .bloc:nth-child(2) img {
    height: 100%;
  }
  #ateliers {
    background-position: right 0 bottom 0;
    padding: 80px 0 500px;
  }
  #ateliers .bloc {
    max-width: 100%;
  }
  .engagements .en-tete .menu-ancres li, .gamme .en-tete .menu-ancres li, .listing-produits .en-tete .menu-ancres li, .entreprise .en-tete .menu-ancres li {
    font-size: 13px;
    padding: 0 15px;
  }
  nav > ul.menu li:last-child a {
    background: none;
    padding: 0
  }
  nav > ul.menu li:nth-last-child(2) {
    margin-left: inherit;
  }
}

@media all and (max-width: 1035px) {
  .wrap, .salade-moment, .liste-produit .field-content, #histoire .flex, #ateliers .flex, #valeurs .flex {
    width: 92%;
  }
  #histoire .flex .bloc:first-child {
    width: 50%;
  }
  #histoire .flex .bloc:nth-child(2) {
    width: 45%;
  }
  #ateliers {
    padding: 80px 0 580px;
  }
  .texte-diaporama {
    max-width: 500px;
    top: 58%;
  }
  .texte-diaporama h1 {
    font-size: 25px;
    line-height: 28px;
  }
  .texte-diaporama p {
    font-size: 20px;
  }
  .engagements-accueil .bloc-engagements {
    width: 80%;
    margin: 0 1% 30px 1%;
    padding: 0;
  }
  .engagements-accueil .bloc.lien {
    margin: 0 1% 15px;
    width: 31%;
  }
  .engagements-accueil {
    justify-content: flex-start
  }
  .engagements-accueil .bloc.lien img {
    width: 100%;
  }
  /* titre */
  .main-contact h2, .entreprise h2, .les-plus-gamme h2, .produits-phares h2, .liste-produit h2, .engagements-accueil h2 {
    font: 700 40px/32px 'Dancing Script', Arial, Helvetica, sans-serif;
  }
  #services h3, .vous-demarquer h3, #infos-marche h3, .entreprise h3, .les-plus-gamme h3, .maison-confiance h3, .engagements-accueil h3, .article-actualite .wrap h3, .listing-recherche li h3 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 4px;
  }
  .gamme .en-tete h1, .page-recherche .en-tete h1, .actualites .en-tete h1, .contact .en-tete h1, .engagements .en-tete h1, .listing-produits .en-tete h1, .entreprise .en-tete h1, .article-actualite .en-tete h1 {
    font-size: 32px;
    line-height: 36px;
  }
  .actualites h2, .actualites-accueil h2 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .liste-produit {
    padding: 40px 0;
  }
}

@media all and (max-width: 885px) {
  .actualites .wrap, .prefooter .wrap {
    width: 96%;
  }
  #infos-marche .bloc:nth-child(2) {
    display: block;
    width: 100%;
    padding: 0;
  }
  #infos-marche .bloc:first-child {
    width: 100%;
    max-width: 100%;
    padding: 60px;
  }
  .actualites-accueil {
    max-width: 100%;
    margin: 20px 0;
  }
  /*#infos-marche .bloc:first-child { max-width: 50%; margin: 0;}*/
  #ateliers {
    background-size: 100%;
    padding: 80px 0 480px;
  }
  .prefooter {
    padding: 30px 0;
  }
  .prefooter .flex .bloc:first-child, .prefooter .flex .bloc:nth-child(3) li {
    width: 100%;
  }
  .prefooter .flex .bloc:nth-child(2), .prefooter .flex .bloc:nth-child(3) {
    width: 48%
  }
  .prefooter .flex .bloc:first-child {
    order: 3;
    -webkit-order: 3
  }
  .prefooter .flex .bloc:nth-child(2) {
    order: 1;
    -webkit-order: 1
  }
  .prefooter .flex .bloc:nth-child(3) {
    order: 2;
    margin-bottom: 15px;
    -webkit-order: 2
  }
  .prefooter .flex .bloc:nth-child(3) li + li {
    margin-top: 15px;
  }
  #menu-footer li {
    display: block;
    padding: 12px 0
  }
  #menu-footer li + li {
    border-left: 0;
    border-top: 1px solid #333;
  }
  .prefooter .flex .bloc:nth-child(3) li:nth-child(3) a {
    padding: 10px 0 10px 60px;
  }
  .engagements .en-tete .menu-ancres li, .gamme .en-tete .menu-ancres li, .listing-produits .en-tete .menu-ancres li, .entreprise .en-tete .menu-ancres li {
    font-size: 12px;
    padding: 0 5px;
  }
  /*.texte-diaporama { color: #000; max-width: 100%; margin:30px 2%; position: relative; text-shadow: none; top: auto; transform: translateY(0px);}*/
}


@media all and (max-width: 785px) {
  .engagements-accueil .bloc.lien {
    width: 48%;
  }
  .messages.status, .messages.error, div.form-item div.password-suggestions {
    width: 100%;
  }
  .salade-moment > *, .main-contact form > *, .salade-moment .bloc:nth-child(2) img, .liste-produit li img, .liste-produit li .titre p, #histoire .flex .bloc:first-child, #histoire .flex .bloc:nth-child(2), #valeurs .flex .bloc {
    width: 100%
  }
  .salade-moment .bloc:nth-child(2), #histoire .flex .bloc:nth-child(2), #valeurs .flex .bloc + .bloc {
    margin-top: 30px;
  }
  .les-plus-gamme .bloc {
    width: 46%;
  }
  #ateliers {
    padding: 80px 0 425px;
  }
  .listing-recherche .container-inline div {
    display: block;
    text-align: center;
  }
  article .listing-recherche input[type=submit] {
    display: block
  }
  .menu-ancres, .cd-product-wrapper .cd-single-point {
    display: none;
  }
}

@media all and (max-width: 650px) {
  .actualites .liste-actu .bloc:first-child {
    width: 100%;
    order: 2;
    -webkit-order: 2;
  }
  .actualites .liste-actu .bloc:last-child {
    width: 100%;
    order: 1;
    -webkit-order: 1;
    margin-bottom: 30px;
  }
  .actualites .liste-actu .bloc:last-child img, #services .flex > *, #services .flex > * img, .liste-produit .field-content a {
    width: 100%;
  }
  .vous-demarquer .bloc:first-child {
    width: 100%;
    display: block;
    padding: 0
  }
  .engagements-accueil .bloc.lien {
    width: 100%;
  }
  .engagements-accueil .bloc-engagements {
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0 5%;
  }
  #ateliers {
    padding: 80px 0 350px;
  }
  .listing-recherche .container-inline label {
    display: block;
    margin-bottom: 10px;
  }
  .liste-produit .field-content a + a {
    margin-top: 15px;
  }
  .main-contact form .form-type-password-confirm .password-strength, .main-contact form .form-type-password-confirm div.password-confirm {
    width: 100%;
    margin-bottom: 15px;
  }
  .texte-diaporama {
    width: 96%;
    margin: 0 auto
  }
  .texte-diaporama h1 {
    font-size: 20px;
    line-height: 24px;
  }
  .texte-diaporama p {
    font-size: 16px;
  }

  header .flex #logo {
    order: 1;
  }
  header .flex #dl-menu {
    order: 3;
  }
  header .flex .langue {
    order: 2;
  }
  .dl-menuwrapper .dl-menu {
    margin: 15px 0 0 0;
    right: 0;
  }
  .dl-menuwrapper .dl-menu.dl-menuopen {
    width: 95vw;
    min-width: 100%;
  }
}

@media all and (max-width: 535px) {
  .img-intro-left {
    float: none;
    margin: 0 0 15px 0;
    width: 100%;
  }
  .img-intro-left img, .vous-demarquer .bloc:nth-child(2) .flex > * {
    width: 100%;
  }
  #menu-footer li {
    display: block;
    padding: 7px 0;
    border-left: 0;
  }
  #ateliers {
    padding: 80px 0 300px;
  }
  #recherche {
    display: none;
  }
  .langue {
    padding: 0 0 0 15px;
  }
  .profile, #user-login {
    width: 90%;
  }
  /*.texte-diaporama h1 { font-size: 20px; line-height: 23px;}*/
}

@media all and (max-width: 460px) {
  .les-plus-gamme .bloc {
    width: 90%;
    margin: 0 auto;
  }
  #chiffres .flex .bloc + .bloc {
    margin-top: 40px;
  }
  .prefooter .flex .bloc:nth-child(2), .prefooter .flex .bloc:nth-child(3) {
    width: 100%
  }
  .actualites .en-tete, .page-recherche .en-tete, .contact .en-tete, .engagements .en-tete, .listing-produits .en-tete, .entreprise .en-tete, .article-actualite .en-tete {
    min-height: 300px;
  }
  .page-user-register article input[type="submit"] {
    float: none;
    width: 100%;
    font-size: 13px;
  }

  /* accueil */
  .maison-confiance .wrap {
    width: 96%;
    margin: 0 auto;
  }
  .engagements-accueil {
    padding: 10px 0 70px;
  }
  .diaporama, article.gamme {
    margin-top: 70px;
  }
  .texte-diaporama h1 {
    font-size: 16px;
    line-height: 20px;
  }

  #ateliers {
    padding: 80px 0 200px;
  }
  article.gamme .flex-direction-nav {
    display: none;
  }
  .gamme .en-tete h1 {
    transform: translateY(-50%);
    font-size: 30px;
    line-height: 35px;
  }
  .gamme .en-tete img {
    width: inherit;
    min-height: 180px;
    object-fit: cover;
  }
  .vous-demarquer .bloc:nth-child(2), #infos-marche .bloc:first-child {
    padding: 60px 35px;
  }
  .vous-demarquer h2 {
    font-size: 40px;
    line-height: 38px;
  }
  article .slide-temoignage li {
    font-size: 18px;
    line-height: 25px;
  }

  .offre-espace-prive {
    font-size: 16px;
    line-height: 22px;
  }
  .main-contact h2, .entreprise h2, .les-plus-gamme h2, .produits-phares h2, .liste-produit h2, .engagements-accueil h2 {
    font: 700 36px/28px 'Dancing Script', Arial, Helvetica, sans-serif;
  }
  #services h3, .vous-demarquer h3, #infos-marche h3, .entreprise h3, .les-plus-gamme h3, .maison-confiance h3, .engagements-accueil h3, .article-actualite .wrap h3, .listing-recherche li h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  .gamme .en-tete h1, .page-recherche .en-tete h1, .actualites .en-tete h1, .contact .en-tete h1, .engagements .en-tete h1, .listing-produits .en-tete h1, .entreprise .en-tete h1, .article-actualite .en-tete h1 {
    font-size: 30px;
    line-height: 34px;
  }
  .actualites h2, .actualites-accueil h2 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  #implantations li {
    margin: 0 8px 10px;
  }

}

#checkBeforeRegisterDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -5px;
  text-align: center;
  line-height: 25px;
  font-weight: 700;
  text-transform: uppercase;

  > div {
    margin: 5px;
    line-height: 1;

    &:nth-child(3) {
      max-width: 274px;
    }

    a {
      display: block;
      font-size: 14px;
      margin-bottom: 6px;
      color: #fff;
      background: #000;
      padding: 10px 10px;
      border-radius: 3px;
      margin-top: 20px;
      border: 2px solid #000;
      white-space: nowrap;

      &:hover {
        font-size: 14px;
        color: #000000;
        background: #c59a20;
        padding: 10px 58px;
        border-radius: 3px;
        margin-top: 20px;
        border: 2px solid #000000;
      }
    }

    small {
      line-height: 1.1;
      white-space: pre-line;
    }
  }
}
